import React from 'react';
import SEOHelmet from '../SEOHelmet';

const LeadingPsychiatristLajpatNagar: React.FC = () => {
  return (
    <div className="bg-white text-pink-700 min-h-screen p-8">
      <SEOHelmet />
      <h1 className="text-4xl font-bold mb-6">Dr. Bushra Zahoor - Leading Psychiatrist in Lajpat Nagar, Delhi</h1>
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Advanced Psychiatric Solutions for Lajpat Nagar</h2>
        <p className="mb-4">Dr. Bushra Zahoor, a gold medalist MBBS and renowned psychiatrist, extends her expertise to Lajpat Nagar through Mind Craft Neuro-Psychiatry Clinic. Known for her compassionate care and innovative approaches, she is the go-to psychiatrist for residents of Lajpat Nagar and surrounding areas in South Delhi.</p>
      </section>
      <section className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Comprehensive Psychiatric Services:</h3>
        <ul className="list-disc list-inside">
          <li>Depression and Anxiety Disorders</li>
          <li>Bipolar Disorder Management</li>
          <li>Relationship and Family Counseling</li>
          <li>Addiction Therapy (including Technology Addiction)</li>
          <li>Work-related Stress and Burnout</li>
          <li>Psychosis and Complex Mental Health Disorders</li>
          <li>Migraine and Psychosomatic Conditions</li>
        </ul>
      </section>
      <section className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Dr. Bushra's Unique Approach:</h3>
        <ul className="list-disc list-inside">
          <li>Personalized treatment plans tailored to individual needs</li>
          <li>Integration of modern psychiatry with holistic wellness techniques</li>
          <li>Focus on long-term mental health and overall wellbeing</li>
          <li>Collaborative care involving family when appropriate</li>
          <li>Expertise in addressing urban lifestyle-related mental health issues</li>
        </ul>
      </section>
      <section className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Dr. Bushra's Qualifications:</h3>
        <ul className="list-disc list-inside">
          <li>MBBS (Gold Medalist)</li>
          <li>MD in Psychiatry</li>
          <li>DNB in Psychiatry</li>
          <li>Assistant Professor, Department of Psychiatry</li>
          <li>Specialized training in advanced psychiatric interventions</li>
        </ul>
      </section>
      <section>
        <h3 className="text-xl font-semibold mb-4">Contact Information</h3>
        <p>Phone: +91-9310330922</p>
        <p>Email: bushra.zhro7@gmail.com</p>
        <p>Address: E-20, basement, Block E, Nizamuddin West, New Delhi, Delhi 110013</p>
        <p>(Easily accessible from Lajpat Nagar)</p>
        <p className="mt-4">Take the first step towards improved mental health. Consult with Dr. Bushra Zahoor, the leading psychiatrist serving Lajpat Nagar and nearby areas in Delhi NCR!</p>
      </section>
    </div>
  );
};

export default LeadingPsychiatristLajpatNagar;