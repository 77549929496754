import React from 'react';
import SEOHelmet from '../SEOHelmet';

const BestPsychiatristKhanMarket: React.FC = () => {
  return (
    <div className="bg-white text-pink-700 min-h-screen p-8">
      <SEOHelmet />
      <h1 className="text-4xl font-bold mb-6">Dr. Bushra Zahoor - Best Psychiatrist in Khan Market, Delhi</h1>
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Exceptional Psychiatric Care Near Khan Market</h2>
        <p className="mb-4">Dr. Bushra Zahoor, a gold medalist MBBS and renowned psychiatrist, offers her expertise near Khan Market at Mind Craft Neuro-Psychiatry Clinic. Her approach combines medical excellence with a deep understanding of the unique stressors faced by urban professionals and residents in this bustling area of Delhi.</p>
      </section>
      <section className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Comprehensive Psychiatric Services:</h3>
        <ul className="list-disc list-inside">
          <li>Depression and Anxiety Treatment</li>
          <li>Bipolar Disorder Management</li>
          <li>Work-Life Balance Counseling</li>
          <li>Stress-Related Disorders</li>
          <li>Technology Addiction Therapy</li>
          <li>Relationship Counseling</li>
          <li>Migraine and Psychosomatic Disorders</li>
        </ul>
      </section>
      <section className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Why Choose Dr. Bushra for Khan Market Area?</h3>
        <ul className="list-disc list-inside">
          <li>Convenient location for professionals and residents</li>
          <li>Tailored treatment plans for high-pressure urban lifestyles</li>
          <li>Expert in managing complex mental health challenges</li>
          <li>State-of-the-art facilities at Mind Craft Clinic</li>
          <li>Holistic approach to mental wellbeing</li>
        </ul>
      </section>
      <section className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Dr. Bushra's Expertise:</h3>
        <ul className="list-disc list-inside">
          <li>MBBS (Gold Medalist)</li>
          <li>MD in Psychiatry</li>
          <li>DNB in Psychiatry</li>
          <li>Assistant Professor, Department of Psychiatry</li>
          <li>Specialized training in advanced psychiatric interventions</li>
        </ul>
      </section>
      <section>
        <h3 className="text-xl font-semibold mb-4">Contact Information</h3>
        <p>Phone: +91-9310330922</p>
        <p>Email: bushra.zhro7@gmail.com</p>
        <p>Address: E-20, basement, Block E, Nizamuddin West, New Delhi, Delhi 110013</p>
        <p>(Conveniently located near Khan Market)</p>
        <p className="mt-4">Experience top-tier psychiatric care near Khan Market. Schedule your consultation with Dr. Bushra Zahoor today and take the first step towards better mental health!</p>
      </section>
    </div>
  );
};

export default BestPsychiatristKhanMarket;