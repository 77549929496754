import React from 'react';
import SEOHelmet from '../SEOHelmet';

const TopPsychiatristGreenPark: React.FC = () => {
  return (
    <div className="bg-white text-pink-700 min-h-screen p-8">
      <SEOHelmet />
      <h1 className="text-4xl font-bold mb-6">Dr. Bushra Zahoor - Leading Psychiatrist & Psychologist in Green Park, Delhi</h1>
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Expert Psychiatric Care in Green Park</h2>
        <p className="mb-4">Dr. Bushra Zahoor, a gold medalist MBBS and renowned psychiatrist, offers exceptional mental health services at Mind Craft Neuro-Psychiatry Clinic in Green Park. With her extensive experience and empathetic approach, she has become the most sought-after mental health professional in South Delhi.</p>
      </section>
      <section className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Comprehensive Psychiatric Services:</h3>
        <ul className="list-disc list-inside">
          <li>Depression and Anxiety Treatment</li>
          <li>Bipolar Disorder Management</li>
          <li>Psychosis and Other Mental Disorders</li>
          <li>Counselling and Psychotherapy</li>
          <li>Relationship Issues</li>
          <li>Migraine Treatment</li>
        </ul>
      </section>
      <section className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Dr. Bushra's Expertise:</h3>
        <ul className="list-disc list-inside">
          <li>MBBS (Gold Medalist), MD (Psychiatry), DNB (Psychiatry)</li>
          <li>Specialized training in advanced psychiatric interventions</li>
          <li>Holistic approach to mental health and well-being</li>
          <li>Expert in treating complex mental health disorders</li>
        </ul>
      </section>
      <section className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Contact Information:</h3>
        <p>Phone: +91-9310330922</p>
        <p>Email: bushra.zhro7@gmail.com</p>
        <p>Address: E-20, basement, Block E, Nizamuddin West, New Delhi, Delhi 110013</p>
      </section>
      <section>
        <h3 className="text-xl font-semibold mb-4">Why Choose Dr. Bushra in Green Park?</h3>
        <p>Located conveniently in Nizamuddin West, Dr. Bushra offers a safe and comfortable environment for your mental health care. Her integrative approach ensures that you receive the most effective treatment tailored to your unique needs, whether you're dealing with depression, anxiety, relationship issues, or other mental health concerns.</p>
        <p className="mt-4">Experience world-class psychiatric care in South Delhi. Schedule your appointment with Dr. Bushra Zahoor at Mind Craft Neuro-Psychiatry Clinic today!</p>
      </section>
    </div>
  );
};

export default TopPsychiatristGreenPark;