import React from 'react';
import SEOHelmet from '../SEOHelmet';

const BestPsychiatristNizzamundin: React.FC = () => {
  return (
    <div className="bg-white text-pink-700 min-h-screen p-8">
      <SEOHelmet
       
      />
      <h1 className="text-4xl font-bold mb-6">Dr. Bushra Zahoor - Top Psychiatrist in Nizamuddin, Delhi</h1>
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Expert Mental Health Care in Nizamuddin</h2>
        <p className="mb-4">Dr. Bushra Zahoor, a gold medalist MBBS and leading psychiatrist in Nizamuddin, offers specialized care for a wide range of mental health conditions at Mind Craft Neuro-Psychiatry Clinic. With her expertise and compassionate approach, she has become the go-to mental health expert in Nizamuddin and surrounding areas of Delhi.</p>
      </section>
      <section className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Comprehensive Psychiatric Services:</h3>
        <ul className="list-disc list-inside">
          <li>Depression and Anxiety Treatment</li>
          <li>Bipolar Disorder Management</li>
          <li>Relationship and Family Counseling</li>
          <li>Stress and Anger Management</li>
          <li>Psychosis and Complex Mental Disorders</li>
          <li>Addiction Therapy (including Technology Addiction)</li>
          <li>Migraine and Psychosomatic Conditions</li>
        </ul>
      </section>
      <section className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Why Choose Dr. Bushra Zahoor?</h3>
        <ul className="list-disc list-inside">
          <li>MBBS (Gold Medalist)</li>
          <li>MD in Psychiatry</li>
          <li>DNB in Psychiatry</li>
          <li>Assistant Professor, Department of Psychiatry</li>
          <li>Personalized treatment plans tailored to individual needs</li>
          <li>State-of-the-art facilities at Mind Craft Neuro-Psychiatry Clinic</li>
          <li>Holistic approach to mental health and well-being</li>
          <li>Expertise in addressing urban lifestyle-related mental health issues</li>
        </ul>
      </section>
      <section>
        <h3 className="text-xl font-semibold mb-4">Contact Information</h3>
        <p>Phone: +91-9310330922</p>
        <p>Email: bushra.zhro7@gmail.com</p>
        <p>Address: E-20, basement, Block E, Nizamuddin West, New Delhi, Delhi 110013</p>
        <p className="mt-4">Book your consultation today with Dr. Bushra Zahoor, the top psychiatrist in Nizamuddin, and take the first step towards better mental health!</p>
      </section>
    </div>
  );
};

export default BestPsychiatristNizzamundin;