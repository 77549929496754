import React from 'react';
import SEOHelmet from '../SEOHelmet';

const ExpertPsychiatristSouthDelhi: React.FC = () => {
  return (
    <div className="bg-white text-pink-700 min-h-screen p-8">
      <SEOHelmet  />
      <h1 className="text-4xl font-bold mb-6">Dr. Bushra Zahoor - Expert Psychiatrist in South Delhi</h1>
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">World-Class Psychiatric Care in South Delhi</h2>
        <p className="mb-4">Dr. Bushra Zahoor, a gold medalist MBBS, brings her expertise to South Delhi, offering top-tier psychiatric treatments at Mind Craft Neuro-Psychiatry Clinic. Her approach combines cutting-edge medical knowledge with deep empathy for her patients, addressing a wide range of mental health concerns.</p>
      </section>
      <section className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Specialized Treatments:</h3>
        <ul className="list-disc list-inside">
          <li>Bipolar Disorder Management</li>
          <li>Depression and Anxiety Treatment</li>
          <li>Counselling and Psychotherapy</li>
          <li>Psychosis and Other Mental Disorders</li>
          <li>Migraine Treatment</li>
          <li>Relationship Issues</li>
        </ul>
      </section>
      <section className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Dr. Bushra's Expertise:</h3>
        <ul className="list-disc list-inside">
          <li>MBBS (Gold Medalist), MD (Psychiatry), DNB (Psychiatry)</li>
          <li>Specializes in complex mental health disorders</li>
          <li>Holistic approach to mental wellbeing</li>
          <li>Extensive experience in treating diverse psychological issues</li>
        </ul>
      </section>
      <section className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Areas Served in South Delhi:</h3>
        <p>Nizamuddin, Nizamuddin East, Jangpura, Aliganj, Khan Market, Lajpat Nagar, Taimour Nagar, South West Delhi, and surrounding areas in Delhi NCR</p>
      </section>
      <section className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Contact Information:</h3>
        <p>Phone: +91-9310330922</p>
        <p>Email: bushra.zhro7@gmail.com</p>
        <p>Address: E-20, basement, Block E, Nizamuddin West, New Delhi, Delhi 110013</p>
      </section>
      <section>
        <h3 className="text-xl font-semibold mb-4">Why Choose Dr. Bushra at Mind Craft Clinic?</h3>
        <p>Dr. Bushra Zahoor offers a safe and comfortable environment for your mental health care at her clinic in Nizamuddin West. Her integrative approach ensures that you receive the most effective treatment tailored to your unique needs, whether you're dealing with depression, anxiety, relationship issues, or other mental health concerns.</p>
        <p className="mt-4">Experience exceptional psychiatric care in South Delhi. Schedule your appointment with Dr. Bushra Zahoor at Mind Craft Neuro-Psychiatry Clinic today!</p>
      </section>
    </div>
  );
};

export default ExpertPsychiatristSouthDelhi;