import React from 'react';
import SEOHelmet from '../SEOHelmet';

const BestMentalHealthDoctorDelhiNCR: React.FC = () => {
  return (
    <div className="bg-white text-pink-700 min-h-screen p-8">
      <SEOHelmet/>
      <h1 className="text-4xl font-bold mb-6">Dr. Bushra Zahoor - Best Mental Health Doctor in Delhi NCR</h1>
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Comprehensive Mental Health Care Across Delhi NCR</h2>
        <p className="mb-4">Dr. Bushra Zahoor, a gold medalist MBBS, is recognized as one of the best mental health doctors in Delhi NCR. Her expertise at Mind Craft Neuro-Psychiatry Clinic covers a wide range of psychiatric conditions, providing hope and healing to patients across the region, including South Delhi, North Delhi, West Delhi, Gurgaon, Noida, and Faridabad.</p>
      </section>
      <section className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Specialized Services:</h3>
        <ul className="list-disc list-inside">
          <li>Depression and Anxiety Disorders</li>
          <li>Bipolar Disorder Treatment</li>
          <li>Psychosis Management</li>
          <li>Child and Adolescent Psychiatry</li>
          <li>Adult and Geriatric Mental Health Care</li>
          <li>Relationship and Family Counseling</li>
          <li>Addiction Therapy (including Technology Addiction)</li>
          <li>Work-related Stress and Burnout</li>
          <li>Migraine and Psychosomatic Disorders</li>
        </ul>
      </section>
      <section className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Why Choose Dr. Bushra Zahoor?</h3>
        <ul className="list-disc list-inside">
          <li>MBBS (Gold Medalist)</li>
          <li>MD in Psychiatry</li>
          <li>DNB in Psychiatry</li>
          <li>Assistant Professor, Department of Psychiatry</li>
          <li>Extensive experience in complex psychiatric cases</li>
          <li>Integrative and holistic approach to mental health</li>
          <li>Personalized treatment plans for each patient</li>
          <li>State-of-the-art facilities at Mind Craft Neuro-Psychiatry Clinic</li>
          <li>Expertise in urban lifestyle-related mental health issues</li>
        </ul>
      </section>
      <section>
        <h3 className="text-xl font-semibold mb-4">Serving Delhi NCR:</h3>
        <p>Locations: Nizamuddin, South Delhi, North Delhi, West Delhi, Gurgaon, Noida, Faridabad</p>
        <h4 className="text-lg font-semibold mt-4 mb-2">Contact Information:</h4>
        <p>Phone: +91-9310330922</p>
        <p>Email: bushra.zhro7@gmail.com</p>
        <p>Main Clinic: E-20, basement, Block E, Nizamuddin West, New Delhi, Delhi 110013</p>
        <p className="mt-4">Take the first step towards better mental health. Consult with Dr. Bushra Zahoor, the best mental health doctor in Delhi NCR!</p>
      </section>
    </div>
  );
};

export default BestMentalHealthDoctorDelhiNCR;